
header.masthead {
    text-align: center;
    color: white;

    background-image: linear-gradient(#20201eb2, rgba(122, 122, 121, 0.5)), url('./images/new.jpg');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size:cover;

}