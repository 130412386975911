.socialSpace {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .socialButton a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 5px;
    padding: 5px;
    border-radius: 10%;
    color: #000;
    text-decoration:underline; /* Ensure no underlines on links */
    transition: background-color 0.5s;
  }
  
  .socialButton a i {
    margin-bottom: 1px; /* Adjust spacing between icon and text */
  }
  
  .socialButton a:hover {
    background-color: #fed136; /* Change background color on hover */
  }
  
  .mailAndNumber {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px; /* Apply margin to the parent div */
  }
  
  p.mailNumberText{
    margin-top: 15px; /* You can remove padding:auto as it doesn't have any effect */
    font-size: 15px;
    font-family: 'verdana';
    font-style: italic;
    font-weight: bold;
    margin-left: 10px;
  }